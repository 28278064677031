import React, { useEffect, useState } from "react";
import "./dashboardProduct.css";
import { AiOutlineCamera } from "react-icons/ai";
import { CustomError } from "../../Components/Toast";
import Select from "react-select";
import ProductComp from "../ProductComponant/ProductComp";
import {
  EmptyImage,
  mobile1,
  mobile2,
  mobile3,
  mobile5,
  mobile6,
  mobile7,
  mobile8,
} from "../../Assets";
import {
  AddAndEditProductApiCall,
  getAllCategoriesApiCall,
  getAllProductsApiCall,
} from "../../Apis/repo";
import { isNullOrEmpty, isNullOrEmptyArray } from "../../Constants/TextUtils";
import LoaderComp from "../Loader/Loader";

const DashboardProduct = () => {
  // const [productImage, setProductImage] = useState("");
  let [productImages, setProductImages] = useState([]);
  let [selectedCategory, setSelectedCategory] = useState(null);
  const [productPrice, setProductPrice] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  let [allCategoriesList, setAllCategoriesList] = useState([]);
  let [allProductList, setAllProductList] = useState([]);
  let [refresh, setRefresh] = useState(false);
  let [loader, setLoader] = useState(false);
  let [loader1, setLoader1] = useState(false);

  useEffect(() => {
    getAllCategories();
    getAllProducts();
  }, [refresh]);

  const getAllCategories = () => {
    setLoader(true);
    getAllCategoriesApiCall()
      .then(({ data }) => {
        setLoader(false);
        if (data.error_code == 0) {
          setAllCategoriesList((allCategoriesList = data.result));
        } else if (data.error_code == 4) {
          alert("No record found in category");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const getAllProducts = () => {
    setLoader1(true);
    getAllProductsApiCall()
      .then(({ data }) => {
        debugger;
        setLoader1(false);
        if (data.error_code == 0) {
          setAllProductList((allProductList = data.result));
        } else if (data.error_code == 4) {
          alert("No record found in products");
        }
      })
      .catch((err) => {
        setLoader1(false);
        console.log("err", err);
      });
  };

  const OnAddProduct = () => {
    if (isNullOrEmptyArray(productImages)) {
      alert("Add Product Image");
    } else if (isNullOrEmpty(selectedCategory)) {
      alert("Select category");
    } else if (isNullOrEmpty(productPrice)) {
      alert("Enter product price");
    } else if (isNullOrEmpty(productName)) {
      alert("Enter product name");
    } else if (isNullOrEmpty(productDescription)) {
      alert("Enter product description");
    } else {
      let formData = new FormData();
      formData.append("product_name", productName);
      formData.append("product_price", productPrice);
      formData.append("category_id", selectedCategory.id);
      formData.append("description", productDescription);
      if (productImages.length > 0) {
        for (let index = 0; index < productImages.length; index++) {
          const element = productImages[index];

          formData.append("images", element, element.name);
        }
      }
      // else formData.append("images", images);

      // for (let index = 0; index < array.length; index++) {
      //   const element = array[index];

      // }
      //       productImages.forEach((image, index) => {
      //         formData.append(`images${index + 1}`, image);
      //       });
      console.log("formData", formData);

      setLoader(true);
      AddAndEditProductApiCall(formData)
        .then(({ data }) => {
          setLoader(false);
          if (data.error_code == 0) {
            setRefresh(!refresh);
            setProductName("");
            setProductImages([]);
            setProductPrice("");
            setProductDescription("");
            setSelectedCategory((selectedCategory = ""));
            alert("Product added successfully");
          } else {
            alert(data.messaage);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
    }
  };

  const handleCategorySelect = (e) => {
    const selectedValue = e.target.value;
    const selectedObject = allCategoriesList.find(
      (item) => item.category_name === selectedValue
    );
    setSelectedCategory(selectedObject);
  };

  const handleFileChange = (e) => {
    if (productImages.length < 5) {
      const newImages = Array.from(e.target.files);
      setProductImages((prevImages) => [...prevImages, ...newImages]);
    } else {
      alert("You cannot selelct more then 5 images");
    }
  };

  return (
    <>
      <div className="UploadImageWrapper">
        <div className="Add_Product_Image_container">
          {/* <img
            src={productImage ? URL.createObjectURL(productImage) : EmptyImage}
            alt="img"
            className="Add_Product_Image_container"
          /> */}

          <div className="Add_Product_Icon_Container">
            <AiOutlineCamera
              color="white"
              size={25}
              className="AddCameraImageStyle"
            />
            <input
              type="file"
              className="popup_formupload_input"
              onChange={(e) => {
                setProductImages([...productImages, e.target.files[0]]);
              }}
              multiple={false}
              accept="image/*"
              // onChange={(e) => {
              //   setProductImage(e.target.files[0]);
              // }}
            />
          </div>
        </div>

        <div
          onClick={() => {
            OnAddProduct();
          }}
          className="CategoryMainButtonWrapperContainer"
        >
          <div className="AddCategoryButton">
            <p className="Add_Category_Text_Style">Add</p>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", gap: "15px" }}>
        {productImages.length > 0 &&
          productImages.map((image, index) => (
            <img
              key={index}
              src={URL.createObjectURL(image)}
              alt={`img-${index}`}
              className="Add_Product_Image_container"
            />
          ))}
      </div>

      <div className="ProductsInputMainContainer">
        <div className="Product_Select_Dropdown">
          <div className={"Add_Emp_Category_Select_Container"}>
            <select
              onChange={(e) => {
                handleCategorySelect(e);
              }}
              id="dropdown"
              className="dropDown"
              value={selectedCategory ? selectedCategory.category_name : ""}
            >
              <option disabled selected value="">
                Select Category
              </option>
              {allCategoriesList.map((item, index) => {
                return (
                  <option key={index} value={item.category_name}>
                    {item.category_name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="Add_Product_detailinput_container_Input">
            <input
              type="text"
              className="Add_Category_input__box"
              placeholder="Name"
              value={productName}
              onChange={(e) => {
                setProductName(e.target.value);
              }}
            />
            {/* <div className="Add_Category_Error__message">{nameErrMsg ? nameErrMsg : null}</div> */}
          </div>
        </div>
        <div className="Product_Select_Dropdown">
          <div className="Add_Product_detailinput_container_Input">
            <input
              type="text"
              className="Add_Category_input__box"
              placeholder="Price"
              value={productPrice}
              onChange={(e) => {
                setProductPrice(e.target.value);
              }}
            />
            {/* <div className="Add_Category_Error__message">{nameErrMsg ? nameErrMsg : null}</div> */}
          </div>

          <div className="Add_Category_Text_Area_Input_Wrapper">
            <textarea
              className="Add_Service_Text_Area_Style"
              placeholder="Description"
              rows="8"
              value={productDescription}
              // cols="70"
              onChange={(e) => {
                setProductDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="All_Category_Text_Style">All Products</div>

      <div className="AddCategoryContentWrapper">
        {allProductList.map((item) => {
          return (
            <ProductComp
              item={item}
              isDashboard={true}
              setRefresh={setRefresh}
              refresh={refresh}
              allCategoriesList={allCategoriesList}
            />
          );
        })}
      </div>
      {loader || loader1 ? <LoaderComp /> : null}
    </>
  );
};

export default DashboardProduct;
