import React, { useState } from "react";
import "./categorySlider.css";
import { EmptyImage } from "../../Assets";
import EditCategoryPopup from "../EditCategoryPopup";
import { BASEURL } from "../../Constants/Constant";
import {
  AddAndEditCategoryApiCall,
  DeleteCategoryApiCall,
} from "../../Apis/repo";
import LoaderComp from "../Loader/Loader";

const CategorySlider = ({ isAdd, item, setRefresh, refresh }) => {
  let [isEdit, setIsEdit] = useState(false);
  let [loader, setLoader] = useState(false);

  const OnDeleteCategory = () => {
    let object = {
      id: item.id,
    };
    setLoader(true);
    DeleteCategoryApiCall(object)
      .then(({ data }) => {
        setLoader(false);
        if (data.error_code == 0) {
          setRefresh(!refresh);
          alert("category deleted successfully");
        } else {
          alert(data.messaage);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <div
        className={
          isAdd
            ? "categorySliderMainContainerWrapper"
            : "categorySliderMainContainer"
        }
      >
        <img
          src={item ? BASEURL.concat(item.image) : EmptyImage}
          alt="img"
          className="categoryLogoImageStyle"
        />
        <div className="CategoryText">{item && item.category_name}</div>
        {isAdd ? (
          <div className="CategoryButtonWrapper">
            <div onClick={OnDeleteCategory} className="CatButtonStyle1">
              Delete
            </div>
            <div
              className="CatButtonStyle"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit
            </div>
          </div>
        ) : null}
      </div>
      <EditCategoryPopup
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        item={item}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      {loader ? <LoaderComp /> : null}
    </>
  );
};

export default CategorySlider;
