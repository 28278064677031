import React, { useState } from "react";
import "./dashboardCategory.css";
import { AiOutlineCamera } from "react-icons/ai";
import { CustomError } from "../../Components/Toast";
import CategorySlider from "../CategorySlider/CategorySlider";
import { Avatar, EmptyImage } from "../../Assets";
import {
  AddAndEditCategoryApiCall,
  getAllCategoriesApiCall,
} from "../../Apis/repo";
import { useEffect } from "react";
import { isNullOrEmpty } from "../../Constants/TextUtils";
import LoaderComp from "../Loader/Loader";

const DashboardCategory = () => {
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [allCategoriesList, setAllCategoriesList] = useState([]);
  let [refresh, setRefresh] = useState(false);
  let [loader, setLoader] = useState(false);
  // const [editCategoryImage, setEditCategoryImage] = useState("");

  // const onSelectImage = (img) => {
  //   if (
  //     img.type.includes("png") ||
  //     img.type.includes("jpg") ||
  //     img.type.includes("jpeg")
  //   ) {
  //     setCategoryImage(img);
  //     setEditCategoryImage("");
  //   } else {
  //     CustomError("Invalid File");
  //   }
  // };

  useEffect(() => {
    getAllCategories();
  }, [refresh]);

  const getAllCategories = () => {
    setLoader(true);
    getAllCategoriesApiCall()
      .then(({ data }) => {
        debugger;
        setLoader(false);
        if (data.error_code == 0) {
          setAllCategoriesList(data.result);
        } else {
          alert(data.messaage);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const OnAddCategory = () => {
    if (isNullOrEmpty(categoryName)) {
      alert("Enter Category Name");
    } else if (isNullOrEmpty(categoryImage)) {
      alert("Add Category Image");
    } else {
      let formData = new FormData();
      formData.append("id", 0);
      formData.append("category_name", categoryName);
      formData.append("image", categoryImage);

      setLoader(true);
      AddAndEditCategoryApiCall(formData)
        .then(({ data }) => {
          setLoader(false);
          if (data.error_code == 0) {
            setCategoryImage("");
            setCategoryName("");
            setRefresh(!refresh);
            // alert("category added successfully");
            // navigate(-1);
          } else {
            alert(data.messaage);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
    }
  };

  return (
    <>
      <div className="UploadImageWrapper">
        <div className="Add_Category_Image_container">
          <img
            src={
              categoryImage ? URL.createObjectURL(categoryImage) : EmptyImage
            }
            alt="img"
            className="Add_Category_Image_container"
          />

          <div className="Add_Category_Icon_Container">
            <AiOutlineCamera
              color="white"
              size={25}
              className="AddCameraImageStyle"
            />
            <input
              type="file"
              className="popup_formupload_input"
              accept=".png, .jpg, jpeg"
              onChange={(e) => {
                setCategoryImage(e.target.files[0]);
              }}
              // onClick={(event) => (event.currentTarget.value = null)}
            />
          </div>
        </div>
        <div
          onClick={OnAddCategory}
          className="CategoryMainButtonWrapperContainer"
        >
          <div className="AddCategoryButton">
            <p className="Add_Category_Text_Style">Add</p>
          </div>
        </div>
      </div>

      <div className="Add_Category_detailinput_container_Input">
        <input
          type="text"
          className="Add_Category_input__box"
          placeholder="Name"
          value={categoryName}
          onChange={(e) => {
            setCategoryName(e.target.value);
          }}
        />
        {/* <div className="Add_Category_Error__message">{nameErrMsg ? nameErrMsg : null}</div> */}
      </div>
      <div className="All_Category_Text_Style">All Categories</div>
      <div className="AddCategoryContentWrapper">
        {allCategoriesList.map((item) => {
          return (
            <CategorySlider
              item={item}
              setRefresh={setRefresh}
              refresh={refresh}
              isAdd={true}
            />
          );
        })}
      </div>
      {loader ? <LoaderComp /> : null}
    </>
  );
};

export default DashboardCategory;
