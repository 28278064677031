import React, { useState, useEffect } from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { BiLogIn } from "react-icons/bi";
import { isNullOrEmpty } from "../../Constants/TextUtils";
import { AdminLoginApiCall } from "../../Apis/repo";
import LoaderComp from "../../Components/Loader/Loader";

const Home = () => {
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  let [loader, setLoader] = useState(false);

  useEffect(() => {
    if (isNullOrEmpty(Token)) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(0);
      };
    }
  }, []);

  const onLogin = () => {
    if (isNullOrEmpty(phoneNumber)) {
      alert("Enter phone number");
    } else if (isNullOrEmpty(password)) {
      alert("Enter password");
    } else {
      let object = {
        phone: phoneNumber,
        password: password,
      };
      setLoader(true);
      AdminLoginApiCall(object)
        .then((response) => {
          setLoader(false);
          if (response.data.status == 200) {
            localStorage.setItem("UserData", response.data.result);
            navigate("/Dashboard");
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
          justifyContent: "center",
        }}
      >
        <div className="Credential_Text_Style">
          Please enter your credentials
        </div>
        <div className="Login_input_container">
          <input
            type="text"
            className="Login_input__box"
            placeholder={"Email/Phone"}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div>
        <div className="Login_input_container">
          <input
            type="password"
            className="Login_input__box"
            placeholder={"Password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>

        <div
          className="LoginButton"
          onClick={() => {
            onLogin();
          }}
        >
          <BiLogIn color="white" size={30} />
          <p className="Login_Text_Style">Login</p>
        </div>
      </div>

      {loader ? <LoaderComp /> : null}
    </>
  );
};

export default Home;
