import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { AiOutlineCamera } from "react-icons/ai";
import { BASEURL } from "../Constants/Constant";
import { EmptyImage } from "../Assets";
import { AddAndEditProductApiCall } from "../Apis/repo";
import { isNullOrEmpty } from "../Constants/TextUtils";
import LoaderComp from "./Loader/Loader";

const EditProductCategory = ({
  setIsEdit,
  isEdit,
  item,
  allCategoriesList,
  setRefresh,
  refresh,
}) => {
  const navigate = useNavigate();
  console.log(item);

  const [editProductImage, setEditProductImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [productPrice, setProductPrice] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  let [loader, setLoader] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);

  let [productImages, setProductImages] = useState([]);

  useEffect(() => {
    setProductImages(item.product_id_FK_images);
  }, [isEdit]);

  const disableScrollOnOpen = () => {
    document.body.style.overflow = "hidden";
  };
  const disableScrollOnClose = () => {
    document.body.style.overflow = "auto";
  };

  const handleCategorySelect = (e) => {
    const selectedValue = e.target.value;
    const selectedObject = allCategoriesList.find(
      (item) => item.category_name === selectedValue
    );
    setSelectedCategory(selectedObject);
  };
  const options = [
    { id: 1, label: "Chocolate" },
    { id: 2, label: "Strawberry" },
    { id: 3, label: "Vanilla" },
  ];

  const onEditProduct = () => {
    let formData = new FormData();
    formData.append("id", item.id);
    formData.append(
      "product_name",
      productName ? productName : item.product_name
    );
    formData.append(
      "product_price",
      productPrice ? productPrice : item.product_price
    );
    formData.append(
      "category_id",
      selectedCategory ? selectedCategory.id : item.category_id
    );
    formData.append(
      "description",
      productDescription ? productDescription : item.description
    );
    // if (deletedIds.length > 0) {
    //   for (let index = 0; index < deletedIds.length; index++) {
    //     const element = deletedIds[index];
    //     debugger;
    //     formData.append(`delete_image[${index}]`, element);
    //   }
    //   debugger;
    // }

    if (productImages.length > 0) {
      for (let index = 0; index < productImages.length; index++) {
        const element = productImages[index];
        debugger;

        formData.append("images", element);
      }
    }
    // else formData.append("images", []);

    // if (productImages.length > 0) {
    //   debugger;
    //   for (let index = 0; index < productImages.length; index++) {
    //     const element = productImages[index];

    //     if (element.image) {
    //       // Handle images with "image" property
    //       formData.append("images", element.image);
    //     } else {
    //       // Handle images without "image" property
    //       formData.append("images", element, element.name);
    //     }
    //   }
    // } else {
    //   debugger;
    //   // Assuming item.product_id_FK_images is an array of image URLs
    //   formData.append("images", item.product_id_FK_images.join(","));
    // }

    setLoader(true);
    AddAndEditProductApiCall(formData)
      .then(({ data }) => {
        debugger;
        setLoader(false);
        if (data.error_code === 0) {
          setEditProductImage("");
          setProductName("");
          setProductPrice("");
          setProductDescription("");
          setSelectedCategory("");
          setRefresh(!refresh);
          navigate("/Dashboard");
          setIsEdit(false);
        } else {
          alert(data.messaage);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const handleFileChange = (e) => {
    if (productImages.length < 5) {
      const newImages = Array.from(e.target.files);
      setProductImages((prevImages) => [...prevImages, ...newImages]);
      // setDeletedIds((prevIds) => [...prevIds, newImages]);
    } else {
      alert("You cannot select more then 5 images");
    }
  };

  const handleFileRemove = (e, id) => {
    if (productImages.length > 0) {
      const index = productImages.findIndex((image) => image.id === id);
      if (index >= 0 && index < productImages.length) {
        setProductImages((prevImages) => {
          const updatedImages = [...prevImages];
          const removedImage = updatedImages.splice(index, 1)[0];
          const removedId = removedImage.id;
          setDeletedIds((prevIds) => [...prevIds, removedId]);
          console.log("Removed image ID:", removedId);
          return updatedImages;
        });
      } else {
        console.error("Invalid index:", index);
      }
    }
  };

  return (
    <>
      <Modal
        onAfterOpen={disableScrollOnOpen}
        onAfterClose={disableScrollOnClose}
        isOpen={isEdit}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setIsEdit(false)}
        className="Product_Modal"
        overlayClassName="Login_Overlay"
      >
        <div>
          <div
            className="CrossIconStyle"
            onClick={() => {
              setProductName(null);
              setProductDescription(null);
              setProductPrice(null);
              setEditProductImage(null);
              setSelectedCategory(null);
              setIsEdit(false);
            }}
          >
            x
          </div>
          <div className="Edit_Credential_Text_Style">Edit Product</div>

          <div style={{ display: "flex" }}>
            <div className="Edit_Product_Image_container">
              <div className="Add_Product_Icon_Container">
                <AiOutlineCamera
                  color="white"
                  size={25}
                  className="AddCameraImageStyle"
                />
                <input
                  type="file"
                  className="popup_formupload_input"
                  onChange={handleFileChange}
                  multiple={false}
                  accept="image/*"
                  // onChange={(e) => {
                  //   setEditProductImage(e.target.files[0]);
                  // }}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", width: "65%" }}>
              {
                productImages.length > 0
                  ? productImages.map((e, index) => {
                      return (
                        <div
                          onClick={() => {
                            handleFileRemove(e, e.id);
                          }}
                          style={{ position: "relative" }}
                          key={index}
                        >
                          <div
                            style={{
                              position: "absolute",
                              right: "-3%",
                              top: "-10%",
                              color: "black",
                              fontSize: "18px",
                              zIndex: 555,
                              fontFamily: "sans-serif",
                              cursor: "pointer",
                            }}
                          >
                            x
                          </div>
                          {e.image ? (
                            <img
                              src={BASEURL.concat(e.image)}
                              alt="img"
                              className="Edit_Product_Image_container"
                              style={{ marginLeft: "5px" }}
                            />
                          ) : (
                            <img
                              src={URL.createObjectURL(e)}
                              alt="img"
                              className="Edit_Product_Image_container"
                              style={{ marginLeft: "5px" }}
                            />
                          )}
                        </div>
                      );
                    })
                  : null
                // item.product_id_FK_images &&
                //   item.product_id_FK_images.map((e, index) => {
                //     return (
                //       <div
                //         onClick={() => {
                //           handleFileRemove(e, index);
                //         }}
                //         style={{ position: "relative" }}
                //         key={index}
                //       >
                //         <div
                //           style={{
                //             position: "absolute",
                //             right: "-3%",
                //             top: "-10%",
                //             color: "black",
                //             fontSize: "18px",
                //             zIndex: 555,
                //             fontFamily: "sans-serif",
                //             cursor: "pointer",
                //           }}
                //         >
                //           x
                //         </div>

                //         <img
                //           src={BASEURL.concat(e.image)}
                //           alt="img"
                //           className="Edit_Product_Image_container"
                //           style={{ marginLeft: "5px" }}
                //         />
                //       </div>
                //     );
                //   })
              }
            </div>
          </div>

          <div className={"Add_Emp_Category_Select_Container"}>
            <select
              onChange={(e) => {
                handleCategorySelect(e);
              }}
              id="dropdown"
              className="dropDownEdit"
              value={
                selectedCategory
                  ? selectedCategory.category_name
                  : item.category_name
              }
            >
              {allCategoriesList.map((e, index) => {
                return (
                  <option key={index} value={e.category_name}>
                    {e.category_name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="Login_input_container">
            <input
              type="text"
              className="Login_input__box"
              placeholder={"Name"}
              onChange={(e) => {
                setProductName(e.target.value);
              }}
              value={productName ? productName : item.product_name}
            />
            {/* <div className="error__message">
              {ErrMsg ? ErrMsg : null}
            </div> */}
          </div>
          <div className="Login_input_container">
            <input
              type="text"
              className="Login_input__box"
              placeholder={"Price"}
              onChange={(e) => {
                setProductPrice(e.target.value);
              }}
              value={productPrice ? productPrice : item.product_price}
            />
            {/* <div className="error__message">
              {ErrMsg ? ErrMsg : null}
            </div> */}
          </div>
          <div className="Add_Category_Text_Area_Input_Wrapper">
            <textarea
              className="Add_Product_Text_Area_Style"
              placeholder="Description"
              rows="8"
              // cols="70"
              onChange={(e) => {
                setProductDescription(e.target.value);
              }}
              value={productDescription ? productDescription : item.description}
            ></textarea>
          </div>

          <div
            className="LoginButton"
            onClick={() => {
              onEditProduct();
              setIsEdit(false);
            }}
          >
            <IoMdAddCircle color="white" size={30} />
            <p className="Login_Text_Style">Submit</p>
          </div>
        </div>
      </Modal>
      {loader ? <LoaderComp /> : null}
    </>
  );
};

export default EditProductCategory;
