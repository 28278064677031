import React, { useState } from "react";
import "./warranty.css";

const Warranty = () => {
  return (
    <>
      <section className="WarrantyMainHeaderContainer">
        <div>Warranty & Returns </div>
      </section>
    </>
  );
};

export default Warranty;
