import React, { useState } from "react";
import "./faqs.css";

const Faqs = () => {
  return (
    <>
      <section className="FAqMainHeaderContainer">
        <div>Faqs</div>
      </section>
    </>
  );
};

export default Faqs;
