import React, { useState } from "react";
import "./paymentInfo.css";

const PaymentInfo = () => {
  return (
    <>
      <section className="PaymentMainHeaderContainer">
        <div>Payment information </div>
      </section>
    </>
  );
};

export default PaymentInfo;
