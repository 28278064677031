import React, { useState } from "react";
import "./productComp.css";
import EditProductCategory from "../EditProductCategory";
import { useNavigate } from "react-router-dom";
import { BASEURL } from "../../Constants/Constant";
import { EmptyImage } from "../../Assets";
import { DeleteProductApiCall } from "../../Apis/repo";
import LoaderComp from "../Loader/Loader";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import CardSlider from "../../Components/CardSlider/CardSlider";

const ProductComp = ({
  item,
  isDashboard,
  allCategoriesList,
  setRefresh,
  refresh,
}) => {
  const navigate = useNavigate();
  let [isEdit, setIsEdit] = useState(false);
  let [editProduct, setEditProduct] = useState("");
  let [loader, setLoader] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const OnDeleteProduct = () => {
    let object = {
      id: item.id,
    };
    setLoader(true);
    DeleteProductApiCall(object)
      .then(({ data }) => {
        setLoader(false);
        if (data.error_code == 0) {
          setRefresh(!refresh);
          alert("Product deleted successfully");
        } else {
          alert(data.messaage);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <div
        onClick={() => {
          if (isDashboard) {
            return null;
          } else {
            navigate("/Productdetail");
          }
        }}
        className={
          isDashboard
            ? "ProductswiperMainContentContainer"
            : "swiperMainContentContainer"
        }
      >
        <div className="PD_Image_MainRapper">
          <div className="FeatureButtonContainer">
            <div ref={navigationPrevRef}>
              <MdKeyboardArrowLeft
                color={"black"}
                size={50}
                className="ProductIconStyle"
              />
            </div>
            <div ref={navigationNextRef}>
              <MdKeyboardArrowRight
                color={"black"}
                size={50}
                className="ProductIconStyle"
              />
            </div>
          </div>
          <CardSlider
            imagesArr={item.product_id_FK_images}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            navigationPrevRef={navigationPrevRef}
            navigationNextRef={navigationNextRef}
          />
        </div>
        <div className="SwiperContentContainer">
          {/* <div className="SwiperHeadingContainer"> */}
          <div>
            <p className="SwiperLocationTextNameStyle">
              {item && item.product_name}
            </p>
          </div>
          <p className="SwiperLocationTextStyle">{item && item.description}</p>
          <div className="PriceContainerStyle">
            <div className="SwiperPrice_Text_Style">{item.product_price}</div>
            <div className="RsTextStyle">Rs</div>
          </div>
        </div>

        {isDashboard ? (
          <div className="Product_CategoryButtonWrapper">
            <div
              onClick={() => {
                OnDeleteProduct();
              }}
              className="CatButtonStyle1"
            >
              Delete
            </div>
            <div
              className="CatButtonStyle"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit
            </div>
          </div>
        ) : null}
      </div>
      <EditProductCategory
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        item={item}
        allCategoriesList={allCategoriesList}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      {loader ? <LoaderComp /> : null}
    </>
  );
};

export default ProductComp;
