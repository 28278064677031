import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { AiOutlineCamera } from "react-icons/ai";
import { AddAndEditCategoryApiCall } from "../Apis/repo";
import { BASEURL } from "../Constants/Constant";
import { EmptyImage } from "../Assets";
import LoaderComp from "./Loader/Loader";
import { isNullOrEmpty } from "../Constants/TextUtils";

const EditCategoryPopup = ({
  setIsEdit,
  isEdit,
  item,
  setRefresh,
  refresh,
}) => {
  console.log(item);
  const navigate = useNavigate();

  const disableScrollOnOpen = () => {
    document.body.style.overflow = "hidden";
  };
  const disableScrollOnClose = () => {
    document.body.style.overflow = "auto";
  };
  const [editCategoryImage, setEditCategoryImage] = useState("");
  const [editCategoryName, setEditCategoryName] = useState(null);
  let [loader, setLoader] = useState(false);

  const OnEditCategory = () => {
    let formData = new FormData();
    formData.append("id", item.id);
    formData.append(
      "category_name",
      editCategoryName ? editCategoryName : item.category_name
    );
    formData.append(
      "image",
      editCategoryImage ? editCategoryImage : item.image
    );
    setIsEdit(false);
    setLoader(true);
    AddAndEditCategoryApiCall(formData)
      .then(({ data }) => {
        setLoader(false);
        if (data.error_code == 0) {
          setRefresh(!refresh);
          navigate("/Dashboard");
          setIsEdit(false);
          alert("category Edited successfully");
        } else {
          alert(data.messaage);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <Modal
        onAfterOpen={disableScrollOnOpen}
        onAfterClose={disableScrollOnClose}
        isOpen={isEdit}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setIsEdit(false)}
        className="Login_Modal"
        overlayClassName="Login_Overlay"
      >
        <div>
          <div
            className="CrossIconStyle"
            onClick={() => {
              setEditCategoryImage(null);
              setEditCategoryName(null);
              setIsEdit(false);
            }}
          >
            x
          </div>
          <div className="Edit_Credential_Text_Style">Edit Category</div>
          <div className="Add_Edit_Category_Image_container">
            <img
              src={
                !isNullOrEmpty(editCategoryImage)
                  ? URL.createObjectURL(editCategoryImage)
                  : BASEURL.concat(item.image)
              }
              alt="img"
              className="Add_Edit_Category_Image_container"
            />
            <div className="Add_Category_Icon_Container">
              <AiOutlineCamera
                color="white"
                size={25}
                className="AddCameraImageStyle"
              />
              <input
                type="file"
                className="popup_formupload_input"
                accept=".png, .jpg, jpeg"
                onChange={(e) => {
                  setEditCategoryImage(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="Login_input_container">
            <input
              type="text"
              className="Login_input__box"
              placeholder={"Name"}
              value={
                editCategoryName != null ? editCategoryName : item.category_name
              }
              onChange={(e) => {
                setEditCategoryName(e.target.value);
              }}
            />
          </div>

          <div
            className="LoginButton"
            onClick={() => {
              OnEditCategory();
            }}
          >
            <IoMdAddCircle color="white" size={30} />
            <p className="Login_Text_Style">Submit</p>
          </div>
        </div>
      </Modal>
      {loader ? <LoaderComp /> : null}
    </>
  );
};

export default EditCategoryPopup;
