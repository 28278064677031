import { BASEURL } from "../Constants/Constant";
import axios from "axios";

export async function AdminLoginApiCall(object) {
  let route = BASEURL.concat("project/api/user/login");
  console.log(`VerifyUser Request : ${route} REQUEST`, object);
  let apiRes = null;
  try {
    apiRes = await axios({
      method: "POST",
      url: route,
      data: object,
    });
  } catch (err) {
    apiRes = err;
    return apiRes;
  } finally {
    return apiRes;
  }
}

export const AddAndEditCategoryApiCall = async (data) => {
  return await axios.post(BASEURL.concat("project/api/category/post"), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

export const getAllCategoriesApiCall = async () => {
  return await axios.get(
    BASEURL.concat(`project/api/category/get-all?page=1&limit=5000`),
    {
      headers: {
        "Content-Type": "application/json",
      },
      "axios-retry": {
        retries: 5,
      },
    }
  );
};

export const getAllProductsApiCall = async () => {
  return await axios.get(
    BASEURL.concat(`project/api/product/get-all?page=1&limit=5000`),
    {
      headers: {
        "Content-Type": "application/json",
      },
      "axios-retry": {
        retries: 5,
      },
    }
  );
};

export const DeleteCategoryApiCall = async (data) => {
  return await axios.post(BASEURL.concat(`project/api/category/delete`), data, {
    headers: {
      "Content-Type": "application/json",
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

export const AddAndEditProductApiCall = async (data) => {
  return await axios.post(BASEURL.concat("project/api/product/post"), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

export const DeleteProductApiCall = async (data) => {
  return await axios.post(BASEURL.concat(`project/api/product/delete`), data, {
    headers: {
      "Content-Type": "application/json",
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

// export async function getAllCategoriesApiCall() {
//   let route = URL.concat(`project/api/category/get-all`);
//   console.log('getPersonalCardById Request : ', route);
//   let apiRes = null;
//   try {
//     apiRes = await axios({
//       method: 'GET',
//       url: route,
//     });
//   } catch (err) {
//     apiRes = err;
//     return apiRes;
//   } finally {
//     return apiRes;
//   }
// }
